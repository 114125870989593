import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonBackButton,
  IonButtons,
  IonModal,
} from "@ionic/react";
import React, { useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { loginUser, resetPassword } from "../firebaseconfig";
import { toast } from "../toast";
import { setUserState } from "../redux/actions";
import { useDispatch } from "react-redux";
import { arrowBack } from "ionicons/icons";

const Login: React.FC = () => {
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [busy, setBusy] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [forgotEmail, setForgotEmail] = useState<string>("");

  const dispatch = useDispatch();

  async function loginResponse() {
    if (password.trim() === "" || email.trim() === "") {
      return toast("Email and password are mandatory.", 5000, "danger");
    }
    setBusy(true);
    const res = await loginUser(email, password);
    if (res) {
      dispatch(setUserState(res.user));
      window.location.reload();
      //history.replace("/dashboard");
    } else {
      // error occured
    }
    setBusy(false);
  }

  function forgotPassword() {
    if (forgotEmail.trim() === "") {
      toast("Please enter your email address.", 5000, "danger");
      return;
    }
    setBusy(true);
    const res = resetPassword(forgotEmail);
    if (res) {
      setShowForgotModal(false);
    } else {
    }
    setBusy(false);
  }

  return (
    <IonPage>
      <IonModal isOpen={showForgotModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowForgotModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Forgot Password</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>
            Enter the email address associated with your account to reset your
            password.
          </p>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Email Address</IonLabel>
              <IonInput
                type="email"
                onIonChange={(e: any) => setForgotEmail(e.target.value)}
              />
            </IonItem>
          </IonList>
          <IonButton onClick={forgotPassword} color="primary">
            Reset Password
          </IonButton>
          <div className="ion-text-center">
            <img
              alt=""
              src={require("../images/misc/forgotpassword.png")}
              style={{ maxWidth: "300px" }}
            />
          </div>
        </IonContent>
      </IonModal>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle className="eina">LogIn</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonLabel position="floating">Email Address</IonLabel>
            <IonInput
              type="email"
              onIonChange={(e: any) => setEmail(e.target.value)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e: any) => setPassword(e.target.value)}
            />
          </IonItem>
        </IonList>
        <IonButton
          fill="clear"
          size="small"
          onClick={() => setShowForgotModal(true)}
        >
          Forgot Password?
        </IonButton>
        <br />
        <IonButton
          color="primary"
          onClick={() => loginResponse()}
          className="ion-margin-top"
        >
          Login
        </IonButton>
        <p>
          Do not have an account? <Link to="/signup">SignUp</Link>
        </p>
        <div className="ion-text-center">
          <img
            alt=""
            src={require("../images/misc/login.png")}
            style={{ maxWidth: "300px" }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
