import {
  IonPage,
  IonIcon,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonFooter,
  IonContent,
} from "@ionic/react";
import React, { useState, FC } from "react";
import Search from "../components/search";
import { searchOutline, receipt, personCircle } from "ionicons/icons";
import HistoryComp from "../components/history";
import Profile from "../components/profile";
import { db } from "../firestoreconfig";
import { useSelector } from "react-redux";
import { toast } from "../toast";

const Dashboard: React.FC = (props) => {
  const [tabSection, SetTabSection] = useState<string>("search");
  const [shouldCheckProps, setShouldCheckProps] = useState<boolean>(true);

  if (shouldCheckProps) {
    if ((props as any).match.params.page !== undefined) {
      SetTabSection((props as any).match.params.page);
      window.history.pushState({}, "", "/dashboard");
      setShouldCheckProps(false);
    }
  }

  const user = useSelector((state: any) => state.user.data);
  const [newAlerts, setNewAlerts] = useState<boolean>(false);

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .where("time", ">=", new Date(new Date().getTime() - 60000))
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (window.location.pathname === "/dashboard") {
            if (change.type === "added") {
              if (change.doc.data().type === "service") {
                toast(
                  change.doc.data().vendor.name +
                    " " +
                    (change.doc.data().didAccept ? "accepted" : "rejected") +
                    " your entry to their queue for " +
                    change.doc.data().service.name +
                    ".",
                  10000,
                  "dark",
                  [
                    {
                      side: "end",
                      text: "View",
                      handler: () => {
                        SetTabSection("history");
                        setNewAlerts(false);
                      },
                    },
                  ]
                );
              }
              setNewAlerts(true);
            }
          }
        });
      });
    setRenderIndex(renderIndex + 1);
  }
  //

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar color="primary">
          <IonTitle className="eina">LocalQueue</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {tabSection === "search" ? <Search /> : null}
        {tabSection === "history" ? <HistoryComp /> : null}
        {tabSection === "profile" ? <Profile /> : null}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonSegment
            value={tabSection}
            onIonChange={(e: any) => SetTabSection(e.target.value!)}
          >
            <IonSegmentButton value="search">
              <IonIcon icon={searchOutline}></IonIcon>
              <IonLabel className="ion-text-capitalize">Search</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="history"
              color={newAlerts ? "danger" : "default"}
            >
              <IonIcon icon={receipt}></IonIcon>
              <IonLabel className="ion-text-capitalize">Queues</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="profile">
              <IonIcon icon={personCircle}></IonIcon>
              <IonLabel className="ion-text-capitalize">Profile</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Dashboard;