import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
  IonItem,
  IonAvatar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import React, { useState } from "react";
import "./Home.css";
import { vendor } from "../myTypes";
import { getVendorData } from "../firebaseconfig_Vendor";
import { call, mail, map, pricetag, hourglass, starHalf, star } from "ionicons/icons";
import { useHistory } from "react-router";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { db } from "../firestoreconfig";
import { useSelector } from "react-redux";
import { toast } from "../toast";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
});

const average = (arr: number[]) => {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};


const VendorPage: React.FC = (props) => {
  let history = useHistory();

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);
  const user = useSelector((state: any) => state.user.data);

  if (renderIndex < 1) {

    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
    .collection("users")
    .doc(user.uid)
    .collection("notifications")
    .where("time", ">=", new Date(new Date().getTime() - 60000))
    .onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        if (window.location.pathname.split("/")[1] === "vendor") {
          if (change.type === "added") {
            if (change.doc.data().type === "service") {
              toast(
                change.doc.data().vendor.name +
                  " " +
                  (change.doc.data().didAccept ? "accepted" : "rejected") +
                  " your entry to their queue for " +
                  change.doc.data().service.name +
                  ".",
                10000,
                "dark",
                [
                  {
                    side: "end",
                    text: "View",
                    handler: () => {
                      history.push("/dashboard/history");
                    },
                  },
                ]
              );
            }
          }
        }
      });
    });


    getVendorInfo();
    setRenderIndex(renderIndex + 1);
  }
  //
  const [vendorData, setVendorData] = useState<vendor>();

  async function getVendorInfo() {
    const pageProps: any = props as object;
    const vendorID = pageProps.match.params.vendorID;
    const response = (await getVendorData(vendorID)) as vendor;
    if (response) {
      setVendorData(response);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle className="eina">
            {vendorData ? (
              vendorData.name
            ) : (
              <IonSkeletonText animated style={{ width: "60%" }} />
            )}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {vendorData ? (
          <IonContent>
            <IonItem>
              <IonAvatar slot="start">
                <img
                  src={require("../images/markers/" +
                    vendorData.category +
                    ".png")}
                  alt=""
                />
              </IonAvatar>
              <IonLabel className="ion-text-wrap">
                <h2 className="eina">About</h2>
                <p>{vendorData.description}</p>
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="success"
                      onClick={() =>
                        window.open("tel:" + vendorData.phone, "_system")
                      }
                    >
                      <IonIcon icon={call}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="danger"
                      onClick={() =>
                        window.open("mailto:" + vendorData.email, "_system")
                      }
                    >
                      <IonIcon icon={mail}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="primary"
                      onClick={() =>
                        window.open(
                          "geo:" +
                            vendorData.location.coords.lat +
                            "," +
                            vendorData.location.coords.lng,
                          "_system"
                        )
                      }
                    >
                      <IonIcon icon={map}></IonIcon>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
            <Map
              // eslint-disable-next-line react/style-prop-object
              style={"mapbox://styles/mapbox/streets-v9"}
              containerStyle={{
                height: "150px",
                width: "100%",
              }}
              center={[
                Number(vendorData.location.coords.lng),
                Number(vendorData.location.coords.lat),
              ]}
              zoom={[15]}
            >
              <Marker
                coordinates={[
                  +vendorData.location.coords.lng!,
                  +vendorData.location.coords.lat!,
                ]}
                anchor="bottom"
              >
                <img
                  alt=""
                  src={require("../images/markers/" +
                    vendorData.category +
                    ".png")}
                />
              </Marker>
            </Map>
            <br />
            {Object.keys(vendorData.serviceData).length > 0 ? (
              <h2 className="eina">Services</h2>
            ) : null}
            <div>
              {Object.keys(vendorData.serviceData)
                .reverse()
                .map((key: any) => (
                  <IonCard
                    key={key}
                    className="serviceCard"
                    onClick={() =>
                      history.push("/service/" + vendorData.id + "/" + key)
                    }
                  >
                    <IonCardHeader>
                      <IonItem lines="none" style={{ padding: "0" }}>
                        <IonCardTitle>
                          {vendorData.serviceData[key].name}
                        </IonCardTitle>
                      </IonItem>

                      <IonCardSubtitle>
                        <IonIcon icon={pricetag}></IonIcon>{" "}
                        {!vendorData.serviceData[key].isGeneral &&
                        vendorData.serviceData[key].isDiscounted ? (
                          <span
                            style={{
                              color: "black",
                              textDecoration: "line-through",
                            }}
                          >
                            <span style={{ color: "red" }}>
                              {"Rs. " +
                                vendorData.serviceData[key].originalPrice +
                                " "}
                            </span>
                          </span>
                        ) : null}
                        {vendorData.serviceData[key].isGeneral
                          ? "Price may vary"
                          : "Rs. " + vendorData.serviceData[key].price}
                      </IonCardSubtitle>
                      <IonCardSubtitle>
                        <IonIcon icon={hourglass}></IonIcon>{" "}
                        {vendorData.serviceData[key].isGeneral
                          ? "Duration may vary"
                          : vendorData.serviceData[key].duration + " minutes"}
                      </IonCardSubtitle>
                      
                      {vendorData.serviceData[key].rating ? (
                        <IonCardSubtitle>
                          {Array(
                            Math.floor(average(vendorData.serviceData[key].rating))
                          ).fill(
                            <IonIcon icon={star} color="warning"></IonIcon>
                          )}
                          {average(vendorData.serviceData[key].rating) -
                            Math.floor(
                              average(vendorData.serviceData[key].rating)
                            ) >=
                          0.4 ? (
                            <IonIcon icon={starHalf} color="warning"></IonIcon>
                          ) : null}
                        </IonCardSubtitle>
                      ) : null}
                    </IonCardHeader>
                    <IonCardContent>
                      <p>
                        <b>{vendorData.serviceData[key].description}</b>
                      </p>
                      <p style={{ color: "#dd0000" }}>
                        Current Queue:{" "}
                        <b>{vendorData.serviceData[key].queue}</b>
                      </p>
                      <p style={{ fontSize: "10px" }}>
                        Click to view details and enter the queue.
                      </p>
                    </IonCardContent>
                  </IonCard>
                ))}
            </div>
          </IonContent>
        ) : (
          <div className="ion-padding custom-skeleton">
            <IonSkeletonText animated style={{ width: "60%" }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: "88%" }} />
            <IonSkeletonText animated style={{ width: "70%" }} />
            <IonSkeletonText animated style={{ width: "60%" }} />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default VendorPage;
