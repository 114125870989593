import { toast } from "./toast";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/performance';

var firebaseConfig = {
	apiKey: "AIzaSyC0qBlDurJ2mOirFgMBEm-FraUNSeNPlrc",
	authDomain: "local-queue.firebaseapp.com",
	databaseURL: "https://local-queue.firebaseio.com",
	projectId: "local-queue",
	storageBucket: "local-queue.appspot.com",
	messagingSenderId: "49135038954",
	appId: "1:49135038954:web:1a5a00f9368d05d60f4293",
	measurementId: "G-F8VTS4BF13",
};

firebase.initializeApp(firebaseConfig);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var perf = firebase.performance();

export function getCurrentUser() {
	return new Promise((resolve, reject) => {
		const unsubsribe = firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				resolve(user);
			} else {
				resolve(null);
			}
			unsubsribe();
		});
	});
}

export async function loginUser(email: string, password: string) {
	try {
		const result = await firebase
			.auth()
			.signInWithEmailAndPassword(email, password);
		toast("Logged In.", 5000, "success");
		return result;
	} catch (error) {
		let errMsg = "";
		switch (error.code) {
			case "auth/invalid-email":
				errMsg = "Enter a valid email address.";
				break;
			case "auth/user-disabled":
				errMsg = "Email address is disabled.";
				break;
			case "auth/user-not-found":
			case "auth/wrong-password":
				errMsg = "User does not exist or password is incorrect.";
				break;

			default:
				errMsg = "Could not LogIn.";
				break;
		}
		toast(errMsg, 5000, "danger");
		return false;
	}
}

export function logoutUser(){
	return firebase.auth().signOut();
}

export async function signupUser(
	name: string,
	email: string,
	password: string
) {
	return firebase
		.auth()
		.createUserWithEmailAndPassword(email, password)
		.then((user) => {
			if (user.user) {
				return user.user
					.updateProfile({
						displayName: name,
					})
					.then(function () {
						toast("SignUp Successful.", 3000, "success");
						return user;
					});
			}
		})
		.catch(function (error) {
			let errMsg = "";
			switch (error.code) {
				case "auth/invalid-email":
					errMsg = "Enter a valid email address.";
					break;
				case "auth/email-already-in-use":
					errMsg = "Email address is already in use.";
					break;
				case "auth/weak-password":
					errMsg = "Password must be atleast 6 characters long.";
					break;

				default:
					errMsg = "Could not SignUp.";
					break;
			}
			toast(errMsg, 5000, "danger");
			return false;
		});
}

export async function resetPassword(email: string){
	return firebase.auth().sendPasswordResetEmail(email).then(function() {
		toast('Check your email for the password reset link.', 5000);
		return true;
	  }).catch(function(error) {
		let errMsg = "";
		switch (error.code) {
			case "auth/invalid-email":
				errMsg = "Enter a valid email address.";
				break;
			case "auth/user-not-found":
				errMsg = "Account does not exist.";
				break;
			default:
				errMsg = "Could not send password reset email.";
				break;
		}
		toast(errMsg, 5000, "danger");
		return false;
	});
}

export async function changeDisplayName(name: string){
	return firebase.auth().currentUser!.updateProfile({
		displayName: name,
	})
	.then(function () {
		toast("Name changed successfully.", 3000, "success");
		return true;
	}).catch(function(){
		toast("Could not change name.", 5000, "danger");
		return false;
	});
}