import React, { useRef } from "react";
import { IonSlide, IonSlides, IonPage, IonContent, IonButton } from "@ionic/react";
import "../theme/slides.css";
import { useHistory } from "react-router";

const Slides: React.FC = () => {
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const history = useHistory();
  return (
    <IonPage>
      <IonContent>
        <IonSlides pager className="slides" ref={slidesRef}>
          <IonSlide className="signup slide">
            <img alt="" src={require("../images/tour/signup.png")}/>
            
            <h2 className="eina">SignUp within Seconds</h2>
            <p>Sign Up in no time and start finding local services and queueing up from the comfort of your home.</p>
            <IonButton color="light" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="search slide">
            <img alt="" src={require("../images/tour/search.png")}/>
            
            <h2 className="eina">Powerful Search</h2>
            <p>Search for nearby services in a snap, powered by filters for prices, queue length, and much more.</p>
            <IonButton color="danger" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="queue slide">
            <img alt="" src={require("../images/tour/queue.png")}/>
            
            <h2 className="eina">Goodbye to Time-killing Queues</h2>
            <p>Choose the vendor and the service you want, and join the queue without stepping out of your home.</p>
            <IonButton color="warning" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="track slide">
            <img alt="" src={require("../images/tour/track.png")}/>
            
            <h2 className="eina">Live Tracking</h2>
            <p>Check your position in the queue real-time, along with the time it would take before your turn.</p>
            <IonButton color="dark" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="wait slide">
            <img alt="" src={require("../images/tour/wait.png")}/>
            
            <h2 className="eina">Wait in Peace</h2>
            <p>Arrive at the vendor's place only when it's your turn - away from the hassle of physical queues.</p>
            <IonButton color="primary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="distancing slide">
            <img alt="" src={require("../images/tour/distancing.png")}/>
            
            <h2 className="eina">Stay Safe</h2>
            <p>By not entering overcrowded queues outside shops, you also keep yourself socially-distanced and safe from any infection.</p>
            <IonButton color="tertiary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="welcome slide">
            <img alt="" src={require("../images/icon.png")}/>
            
            <h2 className="eina">Start Today</h2>
            <p>What are you waiting for? Start today, for free!</p>
            <IonButton color="success" onClick={() => history.push("/")}>
              Start
            </IonButton>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Slides;
