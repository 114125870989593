import React, { useState } from "react";
import {
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonLoading,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonModal,
  IonInput,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  logoutUser,
  resetPassword,
  changeDisplayName,
} from "../firebaseconfig";
import { pencil, arrowBack } from "ionicons/icons";
import AboutContent from "./aboutContent";

const Profile: React.FC = () => {
  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [busy, setBusy] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.data);
  const history = useHistory();

  const [username, setUsername] = useState<string>(user.displayName);
  const [newName, setNewName] = useState<string>(user.displayName);

  async function logoutResponse() {
    await logoutUser();
    history.replace("/");
  }

  function changePassword() {
    setBusy(true);
    const res = resetPassword(user.email);
    if (res) {
    } else {
    }
    setBusy(false);
  }

  function changeName() {
    setBusy(true);
    const res = changeDisplayName(newName);
    if (res) {
      setShowNameChangeModal(false);
      setUsername(newName);
    } else {
    }
    setBusy(false);
  }

  return (
    <IonContent>
      <IonModal isOpen={showNameChangeModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowNameChangeModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Change Name</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>Enter your new name below.</p>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput
                value={newName}
                type="text"
                onIonChange={(e: any) => setNewName(e.target.value)}
              />
            </IonItem>
          </IonList>
          <IonButton onClick={changeName} color="primary">
            Confirm Name
          </IonButton>
        </IonContent>
      </IonModal>
      <IonModal isOpen={showAboutModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowAboutModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">About</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
			<AboutContent/>
		</IonContent>
      </IonModal>
      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonList inset={true}>
        <IonItem>
          <h2>{username}</h2>
          <IonButton slot="end" onClick={() => setShowNameChangeModal(true)}>
            <IonLabel>Edit</IonLabel>
            <IonIcon icon={pencil}></IonIcon>
          </IonButton>
        </IonItem>
        <IonItem>{user.email}</IonItem>
        <IonItem button={true} onClick={changePassword} detail={true}>
          <IonLabel className="comfortaa">Change Password</IonLabel>
        </IonItem>
        <IonItem button={true} onClick={logoutResponse} detail={true}>
          <IonLabel color="danger" className="comfortaa">
            Log Out
          </IonLabel>
        </IonItem>
        <IonItem onClick={() => setShowAboutModal(true)} button detail>
          About
        </IonItem>
        <IonItem onClick={() => window.open("mailto:info@localqueue.in", "_system")} button detail>
          Contact Support
        </IonItem>
      </IonList>
    </IonContent>
  );
};

export default Profile;
