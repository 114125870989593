import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import React from "react";
import "../theme/HomePage.css";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle className="eina">LocalQueue</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding container">
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <img alt="" src={require("../images/icon.png")} width={100} />
            <IonCardTitle className="eina">LocalQueue</IonCardTitle>
            <IonCardSubtitle>
              Virtual Queues for your favourite services - Say Goodbye to hassle
              and crowd, and say hello to social distancing and peace!
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonButton routerLink="/tour" color="warning">
              Launch Tour
            </IonButton>
            <br/>

            <a href="https://play.google.com/store/apps/details?id=in.localqueue&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                style={{width: "200px", maxWidth: "75%"}}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </IonCardContent>
        </IonCard>
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <IonCardTitle>Start Using LocalQueue</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonGrid className="ion-text-center">
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/login" color="primary">
                    Login
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/signup" color="tertiary">
                    SignUp
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Home;
