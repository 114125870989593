import React, { useState } from "react";
import {
  IonContent,
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonText,
  IonButton,
  IonIcon,
  IonModal,
  IonToolbar,
  IonTitle,
  IonAvatar,
  IonRange,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { db } from "../firestoreconfig";
import { Notif, Queue } from "../myTypes";
import { ear, arrowBack, construct, refresh, exit, star } from "ionicons/icons";
import { getQueueStatus, checkOutFromService } from "../firebaseconfig_Vendor";
import { useHistory } from "react-router";
import { toast } from "../toast";

const HistoryComp: React.FC = (props) => {
  let history = useHistory();
  const user = useSelector((state: any) => state.user.data);

  const [notifData, setNotifData] = useState<Array<Notif>>([]);

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .orderBy("time", "desc")
      .onSnapshot(function (querySnapshot) {
        let result: Notif[] = [];

        querySnapshot.forEach(function (doc) {
          let data = doc.data() as Notif;
          data.id = doc.id;
          result.push(data);
        });

        setNotifData(result);
      });

    setRenderIndex(renderIndex + 1);
  }
  //

  const [showTrackModal, setShowTrackModal] = useState<boolean>(false);
  const [trackNotif, setTrackNotif] = useState<Notif>();
  const [trackNotifStatus, setTrackNotifStatus] = useState<Queue>();

  async function trackQueue(notif: Notif) {
    var isFirst = !(
      notifData
        .map((nf, i) => {
          return nf.service.name;
        })
        .indexOf(notif.service.name) !== notifData.indexOf(notif)
    );

    setTrackNotif(notif);
    toast("Loading information...");
    const response: Queue = await getQueueStatus(
      notif.vendor.id,
      notif.serviceID
    );
    if (response) {
      if (!isFirst) {
        response.isQueued = false;
      }
      setTrackNotifStatus(response);
      setShowTrackModal(true);
    } else {
      toast("Could not get tracking information.", 2000, "danger");
    }
  }

  const [showCheckOutModal, setShowCheckOutModal] = useState<boolean>(false);
  const [checkOutNotif, setCheckOutNotif] = useState<Notif>();
  const [serviceRating, setServiceRating] = useState<number>(0);

  async function initCheckOut() {
    const response = await checkOutFromService(checkOutNotif!, serviceRating);
    if (response) {
      toast("Successfully checked out.", 3000, "success");
      setShowCheckOutModal(false);
      setCheckOutNotif(undefined);
      setServiceRating(0);
    } else {
      toast("Could not check out.", 3000, "danger");
    }
  }

  return (
    <IonContent>
      {trackNotifStatus && trackNotif ? (
        <IonModal isOpen={showTrackModal} backdropDismiss={false}>
          <IonToolbar color="clear">
            <IonButton
              slot="start"
              onClick={() => setShowTrackModal(false)}
              fill="clear"
            >
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
            <IonTitle className="eina">
              Track Queue at {trackNotif.service.name}
            </IonTitle>
          </IonToolbar>
          <IonContent className="ion-padding ion-text-center">
            <IonItem
              button={true}
              detail={true}
              onClick={() => {
                history.push(
                  "/service/" +
                    trackNotif.vendor.id +
                    "/" +
                    trackNotif.serviceID
                );
                setShowTrackModal(false);
              }}
            >
              <IonAvatar slot="start" style={{ fontSize: "40px" }}>
                <IonIcon icon={construct}></IonIcon>
              </IonAvatar>
              <IonLabel className="ion-text-wrap">
                <h2 className="eina">{trackNotif.service.name}</h2>
                <h4>
                  at <b>{trackNotif.vendor.name}</b>
                </h4>
                <p>Click to view Service.</p>
              </IonLabel>
            </IonItem>
            <br />

            {trackNotifStatus.isQueued ? (
              <div>
                Your Position in the Queue is
                <br />
                <h1 style={{ fontSize: "2em" }} className="eina">
                  {trackNotifStatus.rank}
                </h1>
                {!trackNotif!.service.isGeneral ? (
                  <p>
                    {"with an estimated time of "}
                    <span className="eina">
                      {trackNotifStatus.rank * trackNotif.service.duration}{" "}
                      minutes
                    </span>
                    {" before it's your turn."}
                  </p>
                ) : null}
                <IonButton
                  color="tertiary"
                  size="small"
                  onClick={() => {
                    trackQueue(trackNotif);
                    toast("Information Refreshed.", 1500, "success");
                  }}
                >
                  <IonIcon icon={refresh} slot="start"></IonIcon>
                  Refresh
                </IonButton>
              </div>
            ) : (
              <div>
                <h3 className="eina">Queue Status</h3>
                This Queue has been fulfilled for you.
                <br />
                <br />
              </div>
            )}

            {!trackNotif!.service.isGeneral ? (
              <IonItem>
                <IonAvatar slot="start">
                  <img
                    alt=""
                    src={require("../images/misc/rupee.png")}
                    style={{ borderRadius: 0, width: "auto", height: "100%" }}
                  />
                </IonAvatar>
                <IonLabel className="ion-text-wrap">
                  <h4 className="eina">
                    {trackNotifStatus.isQueued ? "Due Payment" : "Paid"}
                  </h4>
                  <h1>{trackNotif.service.price}</h1>
                  <p>
                    {trackNotifStatus.isQueued
                      ? "This is the price you booked the service at. The payment will be made at the vendor's location."
                      : "This payment has been made."}
                  </p>
                </IonLabel>
              </IonItem>
            ) : null}
            <br />
            <br />
            {trackNotifStatus.isQueued ? (
              <div>
                <h4 className="eina">Your Reference Code</h4>
                <img
                  style={{ maxWidth: "40%" }}
                  src={
                    "https://api.qrserver.com/v1/create-qr-code/?data=" +
                    trackNotifStatus.id +
                    "&amp;size=75x75"
                  }
                  alt=""
                />
                <h4>{trackNotifStatus.id}</h4>
                <p>Show this code to your vendor when you check-in.</p>
              </div>
            ) : null}
          </IonContent>
        </IonModal>
      ) : null}

      {checkOutNotif ? (
        <IonModal isOpen={showCheckOutModal} backdropDismiss={false}>
          <IonToolbar color="clear">
            <IonButton
              slot="start"
              onClick={() => setShowCheckOutModal(false)}
              fill="clear"
            >
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
            <IonTitle className="eina">
              Check-Out from {checkOutNotif.service.name}
            </IonTitle>
          </IonToolbar>
          <IonContent className="ion-padding">
            Check-out when you walk out of the vendor's place after fulfilliment
            of your service.
            <br />
            <br />
            <em>Want to leave a rating?</em>
            <IonRange
              min={1}
              max={5}
              step={1}
              snaps={true}
              ticks={true}
              color="warning"
              onIonChange={(e) => setServiceRating(e.detail.value as number)}
              value={serviceRating}
              pin
            >
              <IonIcon
                color="warning"
                icon={star}
                slot="start"
                size="small"
              ></IonIcon>
              <IonIcon
                color="warning"
                icon={star}
                slot="end"
                size="large"
              ></IonIcon>
            </IonRange>
            <br />
            <IonButton
              onClick={() => {
                initCheckOut();
              }}
            >
              Check-Out
            </IonButton>
          </IonContent>
        </IonModal>
      ) : null}

      <IonList>
        <IonListHeader style={{ color: "var(--ion-color-primary)" }}>
          <IonLabel className="eina ion-text-wrap">
            <h2 className="eina">Your Queues and Checks-In</h2>
            <p>Manage and view your past and current queues and services.</p>
          </IonLabel>
        </IonListHeader>
        {notifData.length > 0
          ? notifData.map((notif, key) =>
              notif.type === "service" ? (
                <IonItem key={key}>
                  <IonLabel className="ion-text-wrap">
                    <IonText color={notif.didAccept ? "success" : "danger"}>
                      <h4>{notif.didAccept ? "Accepted" : "Rejected"}</h4>
                    </IonText>
                    <h3>
                      <span className="eina">{notif.service.name}</span>
                      {" at "}
                      <span className="eina">{notif.vendor.name}</span>
                    </h3>
                    <p style={{ fontSize: "0.7em" }}>
                      {notif.time.toDate().toLocaleString()}
                    </p>
                    {notif.message !== "" ? (
                      <p>
                        <b>Message</b>
                        <br />
                        {notif.message}
                      </p>
                    ) : null}
                    {notif.didAccept ? (
                      <IonButton
                        color="primary"
                        onClick={() => {
                          trackQueue(notif);
                        }}
                      >
                        <IonIcon icon={ear} slot="start"></IonIcon>
                        Track
                      </IonButton>
                    ) : null}
                  </IonLabel>
                </IonItem>
              ) : notif.type === "turn" ? (
                <IonItem key={key}>
                  <IonLabel className="ion-text-wrap">
                    <IonText color="success">
                      <h4>Check-In</h4>
                    </IonText>
                    <h3>
                      <span className="eina">{notif.service.name}</span>
                      {" at "}
                      <span className="eina">{notif.vendor.name}</span>
                    </h3>
                    <p style={{ fontSize: "0.7em" }}>
                      {notif.time.toDate().toLocaleString()}
                    </p>

                    <p>It's your turn, enjoy the service!</p>
                    {!notif.checkedOut ? (
                      <IonButton
                        color="danger"
                        onClick={() => {
                          setShowCheckOutModal(true);
                          setCheckOutNotif(notif);
                        }}
                      >
                        <IonIcon icon={exit} slot="start"></IonIcon>
                        Check-Out
                      </IonButton>
                    ) : null}
                  </IonLabel>
                </IonItem>
              ) : null
            )
          : null}
      </IonList>
    </IonContent>
  );
};

export default HistoryComp;
