import React, { useState } from "react";
import {
  IonContent,
  IonApp,
  IonItem,
  IonList,
  IonIcon,
  IonRange,
  IonListHeader,
  IonLabel,
  IonCheckbox,
  IonButton,
  IonChip,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonSearchbar,
  IonModal,
  IonToolbar,
  IonTitle,
  IonInput,
} from "@ionic/react";

import { toast } from "../toast";
import { getLocation } from "../geolocation";
import { coords, vendor, SearchFiltersData, MyLocation } from "../myTypes";
import { getServiceData } from "../firebaseconfig_Vendor";
import {
  filter,
  navigateCircle,
  walk,
  pricetag,
  hourglass,
  information,
  star,
  starHalf,
} from "ionicons/icons";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { useHistory } from "react-router-dom";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
});

const average = (arr: number[]) => {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};

const Search: React.FC = () => {
  let history = useHistory();

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    getCurrentLocation();
    setRenderIndex(renderIndex + 1);
  }
  //

  const [locationCoords, setLocationCoords] = useState<coords>();
  const [distanceToSearchIn, setDistanceToSearchIn] = useState<number>(10); //kilometers
  const [minPrice, setMinPrice] = useState<number>(0); //rupees
  const [maxPrice, setMaxPrice] = useState<number>(); //rupees; undefined=no limit
  const [categories, setCategories] = useState<Array<String>>([
    "Shop",
    "Salon",
    "Clinic",
    "Restaurant",
    "Service Center",
    "Workshop"
  ]);
  const [maxQueue, setMaxQueue] = useState<number>(); //undefined=no limit
  const [vendorsData, setVendorsData] = useState<Array<vendor>>([]);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<
    SearchFiltersData | undefined
  >();

  const [searchText, setSearchText] = useState<string>("");
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [selectLocation, setSelectLocation] = useState<string>("");
  const [lastSelectLocation, setLastSelectLocation] = useState<string>("");
  const [selectLocationCoords, setSelectLocationCoords] = useState<coords>();
  const [locationSuggest, setLocationSuggest] = useState<Array<MyLocation>>([]);

  async function getCurrentLocation() {
    await getLocation().then(function (loc) {
      if (loc) {
        toast("Detected current location successfully.", 2000, "success");
        setLocationCoords(loc);
        getServices(loc);
      } else {
        toast("Could not detect location.", 2000, "danger");
        setShowLocationModal(true);
      }
    });
  }

  async function getServices(location: coords | undefined) {
    if (location === undefined) location = locationCoords;
    const response: vendor[] = await getServiceData(
      location!,
      distanceToSearchIn,
      minPrice,
      maxPrice,
      categories,
      maxQueue
    );
    setCurrentFilters({
      dist: distanceToSearchIn,
      minPri: minPrice,
      maxPri: maxPrice,
      cats: categories,
      maxQ: maxQueue,
    });
    setVendorsData(response);
  }

  async function onlyGeocode() {
    let str = selectLocation.replace(";", "");
    if (str.trim() !== "") {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          JSON.parse(this.responseText).features.forEach((e: any) => {
            setSelectLocationCoords({
              lat: e.center[1],
              lng: e.center[0],
            });
          });
        }
      };
      xhttp.open(
        "GET",
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          str +
          ".json?limit=1&country=IN&access_token=pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
        true
      );
      xhttp.send();
    }
  }

  function geocode() {
    if (Math.abs(selectLocation.length - lastSelectLocation.length) > 2) {
      let str = selectLocation.replace(";", "");
      setLastSelectLocation(str);
      if (str.trim() !== "") {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            let response: Array<MyLocation> = [];
            JSON.parse(this.responseText).features.forEach((e: any) => {
              response.push({
                name: e.place_name,
                lat: e.center[1],
                lng: e.center[0],
              });
            });
            setLocationSuggest(response);
          }
        };
        xhttp.open(
          "GET",
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            str +
            ".json?limit=5&country=IN&access_token=pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
          true
        );
        xhttp.send();
      }
    }
  }

  function saveLocation() {
    if (selectLocation === "" || selectLocationCoords == null) {
      toast("You must enter a location to continue.", 2000, "danger");
    } else {
      toast("Location selected.", 2000, "success");
      setShowLocationModal(false);
      setLocationCoords(selectLocationCoords);
      getServices(selectLocationCoords);
    }
  }

  return (
    <IonApp>
      <IonModal isOpen={showLocationModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonTitle className="eina">Select Location</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          We could not detect your location. Enter your current location to
          continue.
          <IonList>
            <IonItem>
              <IonLabel position="floating">Business Location</IonLabel>
              <IonInput
                value={selectLocation}
                onIonChange={(e: any) => {
                  setSelectLocation(e.target.value);
                  geocode();
                }}
                type="text"
                onIonBlur={() => {
                  if (
                    selectLocation.trim() !== "" &&
                    selectLocationCoords == null
                  ) {
                    onlyGeocode();
                  }
                }}
              />
            </IonItem>
            {locationSuggest.map((place, i) => (
              <IonItem
                button={true}
                detail={true}
                onClick={() => {
                  setSelectLocation(place.name);
                  setSelectLocationCoords({
                    lat: place.lat,
                    lng: place.lng,
                  });
                }}
                key={i}
              >
                {place.name}
              </IonItem>
            ))}
            <IonItem lines="none">
              {selectLocationCoords ? (
                <Map
                  // eslint-disable-next-line react/style-prop-object
                  style={"mapbox://styles/mapbox/streets-v9"}
                  containerStyle={{
                    height: "200px",
                    width: "100%",
                  }}
                  center={[
                    Number(selectLocationCoords.lng),
                    Number(selectLocationCoords.lat),
                  ]}
                  zoom={[15]}
                >
                  <Marker
                    coordinates={[
                      +selectLocationCoords.lng!,
                      +selectLocationCoords.lat!,
                    ]}
                    anchor="bottom"
                  >
                    <img
                      alt=""
                      src={require("../images/markers/primary.png")}
                    />
                  </Marker>
                </Map>
              ) : null}
            </IonItem>

            <IonButton color="primary" onClick={() => saveLocation()}>
              Save Location
            </IonButton>
          </IonList>
        </IonContent>
      </IonModal>
      <IonContent className="ion-padding">
        {locationCoords ? (
          <Map
            // eslint-disable-next-line react/style-prop-object
            style={"mapbox://styles/mapbox/streets-v9"}
            containerStyle={{
              height: "150px",
              width: "100%",
            }}
            center={[Number(locationCoords.lng), Number(locationCoords.lat)]}
            zoom={[15]}
          >
            <Marker
              coordinates={[+locationCoords.lng!, +locationCoords.lat!]}
              anchor="bottom"
            >
              <img alt="" src={require("../images/markers/primary.png")} />
            </Marker>
          </Map>
        ) : null}
        <IonList>
          <IonItem
            button={true}
            detail={true}
            color="warning"
            lines="none"
            onClick={() => setShowFilters(!showFilters)}
          >
            <IonIcon icon={filter} slot="start"></IonIcon>
            <p>{showFilters ? "Hide " : "Show "}Filters</p>
          </IonItem>
        </IonList>
        <IonItem hidden={!showFilters}>
          <IonList lines="full" style={{ width: "100%" }}>
            <IonListHeader className="ion-text-wrap">
              Search for places within
            </IonListHeader>
            <IonItem>
              <IonRange
                min={5}
                max={100}
                step={5}
                snaps={true}
                ticks={true}
                color="primary"
                onIonChange={(e) =>
                  setDistanceToSearchIn(e.detail.value as any)
                }
                value={distanceToSearchIn}
              >
                <IonIcon slot="start" icon={navigateCircle} />
                <p slot="end">{distanceToSearchIn} km</p>
              </IonRange>
            </IonItem>
            <IonListHeader className="ion-text-wrap">
              Search within Price Range
            </IonListHeader>
            <IonItem>
              <IonRange
                min={0}
                max={1000}
                step={25}
                snaps={true}
                ticks={true}
                dualKnobs={true}
                color="success"
                onIonChange={(e) => {
                  let range = e.detail.value as any;
                  setMinPrice(range.lower < 1000 ? range.lower : 999);
                  setMaxPrice(range.upper < 1000 ? range.upper : undefined);
                }}
                value={{ lower: minPrice, upper: maxPrice ? maxPrice : 1000 }}
              >
                <p slot="start">Rs. {minPrice}</p>
                <p slot="end">{maxPrice ? "Rs. " + maxPrice : "Any"}</p>
              </IonRange>
            </IonItem>
            <IonListHeader>Select Categories to Search in</IonListHeader>
            <IonItem>
              <IonList>
                {[
                  { val: "Shop", isChecked: categories.includes("Shop") },
                  { val: "Salon", isChecked: categories.includes("Salon") },
                  { val: "Clinic", isChecked: categories.includes("Clinic") },
                  {
                    val: "Restaurant",
                    isChecked: categories.includes("Restaurant"),
                  },
                  {
                    val: "Service Center",
                    isChecked: categories.includes("Service Center"),
                  },
                  {
                    val: "Workshop",
                    isChecked: categories.includes("Workshop"),
                  },
                ].map(({ val, isChecked }, i) => (
                  <IonItem key={i} lines="none">
                    <IonLabel>{val}</IonLabel>
                    <IonCheckbox
                      slot="start"
                      value={val}
                      checked={isChecked}
                      onIonChange={(e) => {
                        let prevCats = categories;
                        if (e.detail.checked) prevCats.push(e.detail.value);
                        else
                          prevCats.splice(prevCats.indexOf(e.detail.value), 1);
                        setCategories(prevCats);
                      }}
                    />
                  </IonItem>
                ))}
              </IonList>
            </IonItem>
            <IonListHeader>Set Maximum Queue Length</IonListHeader>
            <IonItem>
              <IonRange
                min={5}
                max={100}
                step={5}
                snaps={true}
                ticks={true}
                color="warning"
                onIonChange={(e) =>
                  setMaxQueue(
                    e.detail.value < 100 ? (e.detail.value as any) : undefined
                  )
                }
                value={maxQueue ? maxQueue : 101}
              >
                <IonIcon slot="start" icon={walk} />
                <p slot="end">{maxQueue ? maxQueue : "Any"}</p>
              </IonRange>
            </IonItem>
            <IonItem lines="none" className="ion-padding-top">
              <IonButton
                color="primary"
                size="default"
                onClick={() => {
                  setShowFilters(false);
                  getServices(undefined);
                }}
              >
                Apply Filters
              </IonButton>
            </IonItem>
          </IonList>
        </IonItem>
        {currentFilters && (
          <div>
            <IonChip outline={true}>
              <IonIcon icon={navigateCircle} color="danger" />
              <IonLabel>Within {currentFilters.dist} km</IonLabel>
            </IonChip>
            <IonChip outline={true}>
              <IonIcon icon={pricetag} color="danger" />
              <IonLabel>
                Rs. {currentFilters.minPri} to{" "}
                {currentFilters.maxPri ? "Rs. " + currentFilters.maxPri : "Any"}
              </IonLabel>
            </IonChip>
            {currentFilters.cats.map((e, i) => {
              return (
                <IonChip outline={true} key={i}>
                  <IonLabel>{e}</IonLabel>
                </IonChip>
              );
            })}
            <IonChip outline={true}>
              <IonIcon icon={walk} color="danger" />
              <IonLabel>
                Maximum Queue -{" "}
                {currentFilters.maxQ ? currentFilters.maxQ : "Any"}
              </IonLabel>
            </IonChip>
          </div>
        )}
        {vendorsData && (
          <IonSearchbar
            value={searchText}
            onIonChange={(e) => {
              setSearchText(e.detail.value!);
            }}
            className="ion-margin-top"
            animated
          ></IonSearchbar>
        )}
        {vendorsData.map((vendor, i) => (
          <IonList
            key={i}
            style={{ border: "1px solid #ddd" }}
            className="ion-margin-top"
          >
            <IonListHeader className="ion-padding-right">
              <IonLabel>
                <span className="eina">{vendor.name}</span>

                <p>{vendor.category}</p>
              </IonLabel>
              <IonButton
                size="small"
                fill="solid"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  history.push("/vendor/" + vendor.id);
                }}
              >
                More
                <IonIcon icon={information} slot="start"></IonIcon>
              </IonButton>
            </IonListHeader>
            {Object.keys(vendor.serviceData)
              .reverse()
              .map((key: any) =>
                [
                  vendor.name,
                  vendor.category,
                  vendor.serviceData[key].name,
                  vendor.serviceData[key].description,
                  vendor.serviceData[key],
                ]
                  .join(" ")
                  .toLowerCase()
                  .indexOf(searchText.toLowerCase()) > -1 ? (
                  <IonCard
                    key={key}
                    className="serviceCard"
                    onClick={() =>
                      history.push("/service/" + vendor.id + "/" + key)
                    }
                  >
                    <IonCardHeader>
                      <IonItem lines="none" style={{ padding: "0" }}>
                        <IonCardTitle>
                          {vendor.serviceData[key].name}
                        </IonCardTitle>
                      </IonItem>

                      <IonCardSubtitle>
                        <IonIcon icon={pricetag}></IonIcon>{" "}
                        {!vendor.serviceData[key].isGeneral &&
                        vendor.serviceData[key].isDiscounted ? (
                          <span
                            style={{
                              color: "black",
                              textDecoration: "line-through",
                            }}
                          >
                            <span style={{ color: "red" }}>
                              {"Rs. " +
                                vendor.serviceData[key].originalPrice +
                                " "}
                            </span>
                          </span>
                        ) : null}
                        {vendor.serviceData[key].isGeneral
                          ? "Price may vary"
                          : "Rs. " + vendor.serviceData[key].price}
                      </IonCardSubtitle>
                      <IonCardSubtitle>
                        <IonIcon icon={hourglass}></IonIcon>{" "}
                        {vendor.serviceData[key].isGeneral
                          ? "Duration may vary"
                          : vendor.serviceData[key].duration + " minutes"}
                      </IonCardSubtitle>
                      {vendor.serviceData[key].rating ? (
                        <IonCardSubtitle>
                          {Array(
                            Math.floor(average(vendor.serviceData[key].rating))
                          ).fill(
                            <IonIcon icon={star} color="warning"></IonIcon>
                          )}
                          {average(vendor.serviceData[key].rating) -
                            Math.floor(
                              average(vendor.serviceData[key].rating)
                            ) >=
                          0.4 ? (
                            <IonIcon icon={starHalf} color="warning"></IonIcon>
                          ) : null}
                        </IonCardSubtitle>
                      ) : null}
                    </IonCardHeader>
                    <IonCardContent>
                      <p>
                        <b>{vendor.serviceData[key].description}</b>
                      </p>
                      <p style={{ color: "#dd0000" }}>
                        Current Queue: <b>{vendor.serviceData[key].queue}</b>
                      </p>
                      <p style={{ fontSize: "10px" }}>
                        Click to view details and enter the queue.
                      </p>
                    </IonCardContent>
                  </IonCard>
                ) : null
              )}
          </IonList>
        ))}
      </IonContent>
    </IonApp>
  );
};

export default Search;
