import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
  IonItem,
  IonAvatar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonAlert,
  IonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import "./Home.css";
import { vendor } from "../myTypes";
import { useHistory } from "react-router";
import {
  getVendorData,
  requestEntryToQueue,
  checkIfAlreadyInQueue,
} from "../firebaseconfig_Vendor";
import { toast } from "../toast";
import { mail, map, call, walk, star, starHalf } from "ionicons/icons";
import { db } from "../firestoreconfig";
import { useSelector } from "react-redux";

const average = (arr: number[]) => {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};

const ServicePage: React.FC = (props) => {
  let history = useHistory();
  const [busy, setBusy] = useState<boolean>(false);

  const pageProps: any = props as object;
  const serviceID = pageProps.match.params.serviceID;
  const vendorID = pageProps.match.params.vendorID;

  const [queueButtonIsDisabled, setQueueButtonIsDisabled] = useState<boolean>(
    false
  );

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);
  const user = useSelector((state: any) => state.user.data);

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .where("time", ">=", new Date(new Date().getTime() - 60000))
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (window.location.pathname.split("/")[1] === "service") {
            if (change.type === "added") {
              if (change.doc.data().type === "service") {
                toast(
                  change.doc.data().vendor.name +
                    " " +
                    (change.doc.data().didAccept ? "accepted" : "rejected") +
                    " your entry to their queue for " +
                    change.doc.data().service.name +
                    ".",
                  10000,
                  "dark",
                  [
                    {
                      side: "end",
                      text: "View",
                      handler: () => {
                        history.push("/dashboard/history");
                      },
                    },
                  ]
                );
              }
            }
          }
        });
      });

    getVendorInfo();
    setRenderIndex(renderIndex + 1);
  }
  //
  const [vendorData, setVendorData] = useState<vendor>();

  async function getVendorInfo() {
    const response = (await getVendorData(vendorID)) as vendor;
    if (response) {
      if (response.serviceData[serviceID]) {
        setVendorData(response);
      } else {
        toast("This service is unavailable at the moment.", 4000, "danger");
        history.push("/dashboard");
      }
    }
  }

  const [showQueueAlert, setShowQueueAlert] = useState<boolean>(false);

  async function requestQueue() {
    setBusy(true);
    const isAlreadyInQueue = await checkIfAlreadyInQueue(
      vendorData!.id,
      serviceID
    );
    if (isAlreadyInQueue) {
      toast("You are already in the queue.", 3000, "danger");
      setBusy(false);
    } else {
      const response = await requestEntryToQueue(vendorData!, serviceID);
      if (response) {
        toast(
          "Your request has been sent and you will receive a response shortly.",
          5000,
          "success"
        );
        setBusy(false);
        setQueueButtonIsDisabled(true);
      } else {
        toast("Could not send request to enter queue.", 2000, "danger");
        setBusy(false);
      }
    }
  }

  return (
    <IonPage>
      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonAlert
        isOpen={showQueueAlert}
        onDidDismiss={() => setShowQueueAlert(false)}
        cssClass="alertBox"
        header={"Enter Queue?"}
        message={
          "<b>Are you sure you want to enter the queue for this service?</b><br/><br/>You will be registered only after the Vendor accepts your request."
        }
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Yes",
            handler: () => {
              requestQueue();
            },
          },
        ]}
        backdropDismiss={false}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle className="eina">
            {vendorData ? (
              vendorData.serviceData[serviceID].name
            ) : (
              <IonSkeletonText animated style={{ width: "60%" }} />
            )}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {vendorData ? (
          <IonContent>
            <IonItem
              detail={true}
              button={true}
              onClick={() => history.push("/vendor/" + vendorData.id)}
            >
              <IonAvatar slot="start">
                <img
                  src={require("../images/markers/" +
                    vendorData.category +
                    ".png")}
                  alt=""
                />
              </IonAvatar>
              <IonLabel className="ion-text-wrap">
                <p>Service brought to you by</p>
                <h2 className="eina">{vendorData.name}</h2>
                <br />
                <p style={{ fontSize: "0.7em" }}>
                  Click to know more about this Vendor and check out their other
                  services.
                </p>
              </IonLabel>
            </IonItem>
            <IonButton
              expand="full"
              size="large"
              className="eina"
              color="warning"
              onClick={() => setShowQueueAlert(true)}
              disabled={queueButtonIsDisabled}
            >
              Enter Queue
              <IonIcon icon={walk} slot="start"></IonIcon>
            </IonButton>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <h4 className="eina">Description</h4>
                <p>{vendorData.serviceData[serviceID].description}</p>
              </IonLabel>
            </IonItem>
            {vendorData.serviceData[serviceID].rating ? (
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  <h4 className="eina">Customer Rating</h4>
                  {Array(
                    Math.floor(
                      average(vendorData.serviceData[serviceID].rating)
                    )
                  ).fill(<IonIcon icon={star} color="warning"></IonIcon>)}
                  {average(vendorData.serviceData[serviceID].rating) -
                    Math.floor(
                      average(vendorData.serviceData[serviceID].rating)
                    ) >=
                  0.4 ? (
                    <IonIcon icon={starHalf} color="warning"></IonIcon>
                  ) : null}
                  <p>
                    {vendorData.serviceData[serviceID].rating.length} users
                    rated this service{" "}
                    <b>
                      {
                        +average(
                          vendorData.serviceData[serviceID].rating
                        ).toFixed(1)
                      }{" "}
                      stars
                    </b>{" "}
                    out of 5.
                  </p>
                </IonLabel>
              </IonItem>
            ) : null}
            {!vendorData.serviceData[serviceID].isGeneral ? (
              <IonItem>
                <IonAvatar slot="start">
                  <img
                    alt=""
                    src={require("../images/misc/rupee.png")}
                    style={{ borderRadius: 0, width: "auto", height: "100%" }}
                  />
                </IonAvatar>
                <IonLabel className="ion-text-wrap">
                  <h4 className="eina">Price</h4>
                  <h2>
                    {!vendorData.serviceData[serviceID].isGeneral &&
                    vendorData.serviceData[serviceID].isDiscounted ? (
                      <span
                        style={{
                          color: "black",
                          textDecoration: "line-through",
                        }}
                      >
                        <span style={{ color: "red" }}>
                          {vendorData.serviceData[serviceID].originalPrice +
                            " "}
                        </span>
                      </span>
                    ) : null}
                    {vendorData.serviceData[serviceID].price}
                  </h2>
                </IonLabel>
              </IonItem>
            ) : null}
            <IonItem>
              <IonAvatar
                slot="start"
                className="eina"
                style={{ fontSize: "3em", lineHeight: "45px" }}
              >
                {vendorData.serviceData[serviceID].queue}
              </IonAvatar>
              <IonLabel className="ion-text-wrap">
                <h4 className="eina">Current Queue</h4>
                <p>
                  There are {vendorData.serviceData[serviceID].queue} people
                  lined up in the queue.
                </p>
                {!vendorData.serviceData[serviceID].isGeneral ? (
                  <p>
                    Approximate time for queue to clear is{" "}
                    {vendorData.serviceData[serviceID].queue *
                      vendorData.serviceData[serviceID].duration}{" "}
                    minutes.
                  </p>
                ) : null}
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="success"
                      onClick={() =>
                        window.open("tel:" + vendorData.phone, "_system")
                      }
                    >
                      <IonIcon icon={call}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="danger"
                      onClick={() =>
                        window.open("mailto:" + vendorData.email, "_system")
                      }
                    >
                      <IonIcon icon={mail}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="primary"
                      onClick={() =>
                        window.open(
                          "geo:" +
                            vendorData.location.coords.lat +
                            "," +
                            vendorData.location.coords.lng,
                          "_system"
                        )
                      }
                    >
                      <IonIcon icon={map}></IonIcon>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonContent>
        ) : (
          <div className="ion-padding custom-skeleton">
            <IonSkeletonText animated style={{ width: "60%" }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: "88%" }} />
            <IonSkeletonText animated style={{ width: "70%" }} />
            <IonSkeletonText animated style={{ width: "60%" }} />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ServicePage;
